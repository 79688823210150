import React from 'react';
import '../styles/Services.css';

const Service = () => {
  const services = [
    {
      title: "Duct Cleaning",
      problemStatement:
        "Indoor air pollution is a growing concern, as contaminants in HVAC ducts can lead to poor air quality and health issues.",
      solution:
        "Our advanced duct cleaning robot, equipped with UV disinfection and real-time monitoring, ensures clean, safe air for your environment.",
      images: ["/images/duct1.jpg", "/images/duct2.jpg", "/images/duct3.jpg"],
    },
    {
      title: "Facade Cleaning",
      problemStatement:
        "High-rise building facades require regular cleaning, which is often costly, risky, and labor-intensive.",
      solution:
        "Our facade cleaning robot uses AI-based navigation and water-jet technology, making high-rise cleaning safe, cost-effective, and efficient.",
      images: ["/images/facade1.jpg", "/images/facade2.jpg"],
    },
    // Add more services as needed
  ];

  return (
    <div className="services-page">
      <h1>Our Advanced Services</h1>
      {services.map((service, index) => (
        <div key={index} className={`service-section ${index % 2 === 0 ? 'even' : 'odd'}`}>
          <h2>{service.title}</h2>
          <ProblemStatement problem={service.problemStatement} />
          <Solution solution={service.solution} />
          <ServiceImages images={service.images} />
        </div>
      ))}
    </div>
  );
};

const ProblemStatement = ({ problem }) => (
  <div className="problem-statement">
    <h3>Problem Statement</h3>
    <p>{problem}</p>
  </div>
);

const Solution = ({ solution }) => (
  <div className="solution">
    <h3>Our Solution</h3>
    <p>{solution}</p>
  </div>
);

const ServiceImages = ({ images }) => (
  <div className="service-images">
    {images.map((img, index) => (
      <img key={index} src={img} alt={`Service Image ${index + 1}`} className="service-image" />
    ))}
  </div>
);

export default Service;
