import React, { useState } from 'react';
import '../styles/Contact.css';
import { FaLinkedin, FaInstagram, FaMapMarkerAlt, FaPhone, FaEnvelope } from 'react-icons/fa';
import { useForm, ValidationError } from '@formspree/react';


const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  // Validation function
  const validateForm = () => {
    let errors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formData.name) {
      errors.name = "Name is required";
    }

    if (!formData.email || !emailPattern.test(formData.email)) {
      errors.email = "Please enter a valid email address";
    }

    if (!formData.message) {
      errors.message = "Message is required";
    }

    return errors;
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleNumberInput = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      // Only update the state if the input is a number
      setFormData({
        ...formData,
        phone: value
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsSubmitting(true);

    // Validate the form
    const validationErrors = validateForm();
    setValidationErrors(validationErrors);

    // If there are validation errors, don't submit
    if (Object.keys(validationErrors).length > 0) {
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await fetch('https://formspree.io/f/xldebzob', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        setSubmitted(true);
      } else {
        throw new Error('Failed to send message');
      }
    } catch (err) {
      setError(err.message);
    }

    setIsSubmitting(false);
  };

  return (
    <div className="contact-page">
      <h1>Contact Us</h1>
      <div className="contact-sections">
        {/* Contact Information */}
        <div className="contact-info">
          <h2>Feel Free to Contact Us!</h2>
          <div className="info-item">
            <FaEnvelope className="icon" />
            <p>Email</p>
            <a href="mailto:hr@raybotlabs.com">hr@raybotlabs.com</a> <br></br>
            <a href="mailto:contact@raybotlabs.com">contact@raybotlabs.com</a>
          </div>
          <div className="info-item">
            <FaPhone className="icon" />
            <p>Phone</p>
            <a href="tel:+917796403251">(+91) 7796403251</a> <br></br>
            <a href="tel:+917798403251">(+91) 7798403251</a>
          </div>
        </div>

 {/* Contact Form */}
 <div className="contact-form">
          <h2>Send Us a Message</h2>
        {submitted ? (
          <p className="success-message">Thank you! Your message has been sent.</p>
        ) : (
          <form onSubmit={handleSubmit} className="contact-form">
            <input
              className="form-input"
              type="text"
              name="name"
              placeholder="Your Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            {validationErrors.name && <p className="error-message">{validationErrors.name}</p>}

            <input
              className="form-input"
              type="email"
              name="email"
              placeholder="Your Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {validationErrors.email && <p className="error-message">{validationErrors.email}</p>}

            <input
              className="form-input"
              type="text"
              name="phone"
              placeholder="Your Phone Number"
              value={formData.phone}
              onChange={handleNumberInput}  // Restrict input to numbers only
              required
            />
            {validationErrors.phone && <p className="error-message">{validationErrors.phone}</p>}

            <textarea
              className="form-textarea"
              name="message"
              placeholder="Your Message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
            {validationErrors.message && <p className="error-message">{validationErrors.message}</p>}

            <button type="submit" className="form-submit-button" disabled={isSubmitting}>
              {isSubmitting ? 'Sending...' : 'Send Message'}
            </button>
          </form>
        )}
        {error && <p className="error-message">Error: {error}</p>}
        </div>

        {/* Social Media Links */}
        <div className="contact-social">
          {/* Map Section */}
          <div className="map-container">
            <h3>Find Us Here</h3>
            <iframe
              title="Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3749.0752936641893!2d73.77531617443299!3d20.005354381400103!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bddec3e0eca98f5%3A0x110f14878749d90!2sRaybot%20Automation%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1727603021745!5m2!1sen!2sin"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>

          {/* Follow Us Section */}
          <div className="follow-us">
            <h2>Follow Us</h2>
            <div className="social-icons3">
              <a href="https://www.linkedin.com/company/raybot-automation/" target="_blank" rel="noopener noreferrer">
                <FaLinkedin />
              </a>
              <a href="https://www.instagram.com/raybot_labs/" target="_blank" rel="noopener noreferrer">
                <FaInstagram />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
