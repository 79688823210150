import React from 'react';
import '../styles/Careers.css';
import { FaArrowRight, FaCheckCircle } from 'react-icons/fa';

const Careers = () => {
  return (
    <div className="careers-page">
      {/* Hero Section */}
      <section className="careers-hero">
        <h1>Join Our Team</h1>
        <p>Build your future with us! Explore exciting career opportunities and become part of our innovative team.</p>
      </section>

      {/* Why Join Us Section */}
      <section className="why-join-us">
        <h2>Why Join Us!</h2>
        <div className="benefits">
          <div className="benefit-item">
            <FaCheckCircle />
            <h3>Competitive Salary</h3>
            <p>We offer industry-leading salaries and benefits.</p>
          </div>
          <div className="benefit-item">
            <FaCheckCircle />
            <h3>Remote Flexibility</h3>
            <p>Work from anywhere or join us at our modern office.</p>
          </div>
        </div>
      </section>

      {/* Job Listings Section */}
      <section id="job-listings" className="job-listings">
        <h2>Current Openings</h2>
        <div className="job-listing">
          <h3>Frontend Developer</h3>
          <p>Location: Remote | Full-Time</p>
        </div>
        <div className="job-listing">
          <h3>Backend Developer</h3>
          <p>Location: On-Site | Full-Time</p>
        </div>
        {/* Add more job listings as needed */}

        {/* Common Application Information */}
        <div className="application-info">
          <p>
            To apply, please send your resume to <a href="mailto:hr@raybotautomation.com">hr@raybotautomation.com</a>
          </p>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="faq-section">
        <h2>Frequently Asked Questions</h2>
        <div className="faq-item">
          <h3>What benefits do you offer?</h3>
          <p>We provide comprehensive healthcare, paid time off, and retirement plans.</p>
        </div>
      </section>
    </div>
  );
};

export default Careers;
