import React, { useState, useEffect } from 'react';
import '../styles/About.css';
import missionIcon from '../Images/mvg/4.png';
import visionIcon from '../Images/mvg/6.png';
import valuesIcon from '../Images/mvg/7.png';
import teamImage from '../Images/Icons/instagram.png'; // Replace with actual image path
import { FaLinkedin, FaTwitter, FaGithub } from 'react-icons/fa';
import '@fortawesome/fontawesome-free/css/all.min.css';

import backgroundVideo from '../Videos/Raybot Automation.mp4';

import personIcon from '../Images/person-walking-solid.svg';  // Use an icon to represent the person climbing

const achievements = [
  { year: '2018', title: 'Company Founded', description: 'Raybot Automation was founded to pioneer robotics solutions.' },
  { year: '2019', title: 'First Product Launch', description: 'Our first HVAC duct cleaning robot hit the market.' },
  { year: '2020', title: 'Partnerships', description: 'Secured partnerships to enhance robotics technology.' },
  { year: '2021', title: 'Global Expansion', description: 'Expanded to international markets.' },
  { year: '2023', title: 'Award-Winning Design', description: 'Received the Robotics Innovation Award for our new robot.' }
];

function AboutUs() {

  const [activeStep, setActiveStep] = useState(0);

  // Automatically cycle through steps to simulate "walking"
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveStep(prevStep => (prevStep + 1) % achievements.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const teamMembers = [
    {
      name: "Jane Doe",
      role: "Chief Robotics Engineer",
      image: "path/to/image.jpg",
      bio: "Expert in robotics with over 10 years of experience...",
      social: {
        linkedin: "https://linkedin.com/in/janedoe",
        twitter: "https://twitter.com/janedoe",
        github: "https://github.com/janedoe",
      },
      
    },
    {
      name: "Jane Doe",
      role: "Chief Robotics Engineer",
      image: "path/to/image.jpg",
      bio: "Expert in robotics with over 10 years of experience...",
      social: {
        linkedin: "https://linkedin.com/in/janedoe",
        twitter: "https://twitter.com/janedoe",
        github: "https://github.com/janedoe",
      },
      
    },
    // Add more team members as needed
  ];


  return (
    <div className="about-page">

      <div className="about-video-container">
        <video autoPlay loop muted playsInline className="fullscreen-video">
          <source src={backgroundVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      {/* Mission, Vision, and Values */}
      <section id="mission-section" className="mv-values">
        <div className="mv-values-header">
          <h2>Our Mission, Vision, and Values</h2>
        </div>
        <div className="mv-cards">
          <div className="mv-card">
            <img src={missionIcon} alt="Mission Icon" />
            <h3>Mission</h3>
            <p>To develop groundbreaking robotics that solve real-world challenges.</p>
          </div>
          <div className="mv-card">
            <img src={visionIcon} alt="Vision Icon" />
            <h3>Vision</h3>
            <p>To lead the future in automation and AI-driven solutions globally.</p>
          </div>
          <div className="mv-card">
            <img src={valuesIcon} alt="Values Icon" />
            <h3>Values</h3>
            <p>Integrity, innovation, and commitment to excellence.</p>
          </div>
        </div>
      </section>

      <section className="timeline-section">
        <h2>Our Journey</h2>
        <div className="stairs-container">
          {achievements.map((achievement, index) => (
            <div
              key={index}
              className={`stair-step ${index === activeStep ? 'active' : ''}`}
              style={{ width: `${100 + index * 20}px` }}  // Each step progressively larger
            >
              {index === activeStep && (
                <img src={personIcon} alt="Walking person" className="person-icon" />
              )}
              <div className="step-year">{achievement.year}</div>
            </div>
          ))}
        </div>
        {/* Description shown below stairs */}
        <div className="step-description">
          <h3>{achievements[activeStep].title}</h3>
          <p>{achievements[activeStep].description}</p>
        </div>
      </section>

      <section className="team-section">
      <h2>Meet Our Team</h2>
      <div className="team-grid">
        {teamMembers.map((member, index) => (
          <div key={index} className="team-member">
            <div className="team-card">
              <div className="team-front">
                <img src={member.image} alt={member.name} className="team-image" />
                <h3>{member.name}</h3>
                <p>{member.role}</p>
              </div>
              <div className="team-back">
                <p>{member.bio}</p>
                <div className="social-icons">
                  {member.social?.linkedin && (
                    <a href={member.social.linkedin} target="_blank" rel="noopener noreferrer">
                      <FaLinkedin className="icon linkedin-icon" />
                    </a>
                  )}
                  {member.social?.twitter && (
                    <a href={member.social.twitter} target="_blank" rel="noopener noreferrer">
                      <FaTwitter className="icon twitter-icon" />
                    </a>
                  )}
                  {member.social?.github && (
                    <a href={member.social.github} target="_blank" rel="noopener noreferrer">
                      <FaGithub className="icon github-icon" />
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
    </div>
  );
}

export default AboutUs;
