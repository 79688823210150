// Footer.js
import React from 'react';
import '../styles/Footer.css'; // Import footer CSS for styling
import logo from '../Images/logo_black_square-removebg-preview.png'; // Adjust path for logo import
import feature9 from '../Images/Icons/instagram.png';
import feature10 from '../Images/Icons/linkedin.png';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-left">
          <img src={logo} alt="Company Logo" className="footer-logo" />
          <p>
            Raybot Automation provides innovative robotics solutions to meet the challenges of modern industries.
            Contact us to learn more about our services.
          </p>
        </div>
        <div className="footer-center">
          <h3>Quick Links</h3>
          <ul>
          <li><a href="/">Home</a></li>
            <li><a href="/about">About Us</a></li>
            <li><a href="/services">Services</a></li>
            <li><a href="/contact">Contact</a></li>
            <li><a href="/careers">Careers</a></li>
          </ul>
        </div>
        <div className="footer-right">
          <h3>Contact Us</h3>
          <p><strong>Phone:</strong> (+91) 7796403251, 7798403251</p>
          <p><strong>Email:</strong> teamraybotlab@gmail.com</p>
          <h3>Follow Us</h3>
          <div className="social-icons">
            <a href="https://www.linkedin.com/company/raybot-automation/" target="_blank" rel="noopener noreferrer">
              <img src={feature10} alt="LinkedIn" />
            </a>
            <a href="https://www.instagram.com/raybot_labs/" target="_blank" rel="noopener noreferrer">
              <img src={feature9} alt="Instagram" />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>© 2024 Raybot Automation. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
