import React, { useEffect } from 'react';
import '../styles/Home.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import robotImage from '../Images/hero.png'; // Import your hero image

import feature1 from '../Images/Services/Duct_Cleaning_Robot.png';
import feature2 from '../Images/Services/facade_cleaning_robot.jpg';
import feature9 from '../Images/Services/DALL·E 2024-10-30 21.55.48 - A stylish, modern air purifier with a sleek, cylindrical design, featuring soft LED lights at the top and a digital display panel. The air purifier ha.webp';


import feature3 from '../Images/Why us/Cost_Effective_Solutions.png';
import feature4 from '../Images/Why us/Energy_Efficiency.png';
import feature5 from '../Images/Why us/Advanced_Robotics_Technology.png';
import feature6 from '../Images/Trusted By/1.png';
import feature7 from '../Images/Trusted By/2.png';
import feature8 from '../Images/Trusted By/3.jpg';


function Home() {

    const sliderImages = [robotImage, robotImage, robotImage]; // Add paths to your images here

    // Slider settings
    const settings = {
        dots: true,
        infinite: true,
        speed: 400,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true
    };


    useEffect(() => {
        // Select all service rows
        const serviceRows = document.querySelectorAll('.service-row');

        // Intersection Observer to trigger animations when in view
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('show');  // Adds the 'show' class to trigger animation
                    observer.unobserve(entry.target);  // Stop observing once animation is triggered
                }
            });
        }, { threshold: 0.1 });

        // Apply observer to each service row
        serviceRows.forEach(row => observer.observe(row));

        // Cleanup observer on component unmount
        return () => observer.disconnect();


    }, []);



    return (
        <div>
            <div className="hero-container">
                {/* Common Heading */}
                <h1 className="hero-heading">"Empowering Clean Air, One Duct at a Time"</h1>

                <div className="hero-section">
                    {/* Left Side - Image Slider */}
                    <div className="hero-image">
                        <Slider {...settings}>
                            {sliderImages.map((image, index) => (
                                <div key={index}>
                                    <img src={image} alt={`Slide ${index + 1}`} />
                                </div>
                            ))}
                        </Slider>
                    </div>

                    {/* Right Side - Points about the Robot */}
                    <div className="hero-content">

                        <ul>
                            <li>Cost-effective</li>
                            <li>UV disinfection</li>
                            <li>Fully Made in India</li>
                            <li>User-friendly joystick control</li>
                            <li>Real-time dual-camera monitoring</li>
                            <li>Meets strict hygiene and safety standards</li>
                        </ul>
                    </div>
                </div>
            </div>


            {/* About Us Preview Section */}
            <div className="about-us-preview">
                <h2>About Us</h2>
                <p>
                    At Raybot Automation, we are committed to transforming industries with advanced, sustainable robotics that address real-world challenges. Our solutions prioritize efficiency, safety, and sustainability, empowering businesses to thrive in a rapidly changing world. Driven by a passion for innovation, we craft cutting-edge technologies that enhance productivity and promote responsible operations, solving the challenges of tomorrow, today.
                </p>
                <a href="/about" className="about-us-button">
                    Read More
                </a>
            </div>

            {/* Our Services Section with alternating layout */}
            <div className="services-overview">
                <h2>Our Services</h2>

                {/* First Service: Image on left, description on right */}
                <div className="service-row">
                    <div className="service-img">
                        <img src={feature2} alt="Facade Cleaning Robot" />
                    </div>
                    <div className="service-desc">
                        <h3>Facade Cleaning Robots</h3>
                        <p>
                            Our facade cleaning robots are designed to clean high-rise buildings safely and efficiently.
                            They offer precision and security for cleaning skyscraper windows and facades without human intervention.
                        </p>
                    </div>

                </div>

                {/* Second Service: Description on left, image on right */}
                <div className="service-row reverse">

                    <div className="service-img">
                        <img src={feature1} alt="Duct Cleaning Robot" />
                    </div>
                    <div className="service-desc">
                        <h3>Duct Cleaning Robots</h3>
                        <p>
                            Keep your ducts clean and improve indoor air quality with our cutting-edge duct cleaning robots.
                            These robots are designed for efficiency, cleanliness, and cost-effective maintenance.
                        </p>
                    </div>
                </div>

                {/* Third Service: Description on left, image on right */}
                <div className="service-row reverse">

                    <div className="service-img">
                        <img src={feature9} alt="Duct Cleaning Robot" />
                    </div>
                    <div className="service-desc">
                        <h3>Eco-Friendly Air Purification</h3>
                        <p>
                            Our commitment: Cleaner air today, a greener planet tomorrow.
                            Experience the purity of nature indoors with an eco-friendly air purifier designed to protect your health and the planet.
                        </p>
                    </div>
                </div>
                <a href="/Services" className="about-us-button">
                    Explore More Services
                </a>
            </div>

            {/* Key Features/Benefits Section */}
            <div className="key-features">
                <h2>Why Choose Us?</h2>
                <div className="features-list">
                    <div className="feature-item">
                        <img src={feature3} alt="Cost Effective" />
                        <h3>Cost-Effective Solutions</h3>
                        <p>Our robots provide affordable solutions, reducing operational costs and improving productivity.</p>
                    </div>
                    <div className="feature-item">
                        <img src={feature4} alt="Energy Efficient" />
                        <h3>Energy Efficiency</h3>
                        <p>Our robots are designed to consume less energy, helping you save on energy costs while being environmentally friendly.</p>
                    </div>
                    <div className="feature-item">
                        <img src={feature5} alt="Advanced Technology" />
                        <h3>Advanced  Technology</h3>
                        <p>We utilize cutting-edge robotics and AI technology to ensure precision and efficiency in every task.</p>
                    </div>
                </div>
            </div>

            {/* Trusted By Section */}
            <div className="trusted-by">
                <h2>Trusted By</h2>
                <div className="trusted-logos">
                    <img src={feature6} alt="Company 1" />
                    <img src={feature7} alt="Company 2" />
                    <img src={feature8} alt="Company 3" />
                </div>
            </div>
        </div >
    );
}

export default Home;
